import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import { latLng, Icon, icon } from "leaflet";
import shadowUrl from "@/assets/images/logo/shadow.png";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

let cFilter = (fn, a) => {
  const f = [];
  for (let i = 0; i < a.length; i++) {
    if (fn(a[i])) {
      f.push(a[i]);
    }
  }
  return f;
};

let mergeDuplicatesByIds = (ar, a) =>
  ar.reduce((r, c) => {
    let d = r.findIndex((x) => x[a] === c[a]);
    return d >= 0
      ? [...cFilter((s, f) => f != d, r), { ...r[d], ...c }]
      : [...r, c];
  }, []);

export default new Vuex.Store({
  state: () => ({
    categories: null,
    gifts: [],
    user: false,
    userGifts: null,
    loyaltyCards: null,
    transactions: null,
    history: null,
    systemNotifications: null,
    companyStores: null,
    token: null,
  }),
  getters: {
    categories: (state) => state.categories,
    gifts: (state) => state.gifts,
    userGifts: (state) => state.userGifts,
    user: (state) => state.user,
    loyaltyCards: (state) => state.loyaltyCards,
    transactions: (state) => state.transactions,
    history: (state) => state.history,
    systemNotifications: (state) => state.systemNotifications,
    companyStores: (state) => state.companyStores,
    token: (state) => state.token,
  },
  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
    },
    setGifts(state, payload) {
      state.gifts = payload;
    },
    setGiftsPagination(state, payload) {
      state.giftsPagination = payload;
    },
    setUserGifts(state, payload) {
      state.userGifts = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setSystemNotifications(state, payload) {
      state.systemNotifications = payload;
    },
    setLoyaltyCards(state, payload) {
      state.loyaltyCards = payload;
    },
    setHistory(state, payload) {
      state.history = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setTransactions(state, payload) {
      state.transactions = payload;
    },
    setCompanyStores(state, payload) {
      const keys_to_keep = [
        "id",
        "map_logo",
        "coordinates",
        "name",
        "address",
        "primary_phone",
      ];
      const result = payload.map((e) => {
        const obj = {};
        keys_to_keep.forEach((k) => (obj[k] = e[k]));
        return obj;
      });
      const resultArray = result.map((elm) => ({
        id: elm.id,
        map_logo: icon(
          Object.assign({}, Icon.Default.prototype.options, {
            iconUrl: elm.map_logo,
            shadowUrl,
          })
        ),
        latlng: latLng(elm.coordinates.lat, elm.coordinates.lon),
        text: elm.name + "<br>" + elm.address + "<br>" + elm.primary_phone,
      }));
      state.companyStores = resultArray;
      console.log(payload);
    },
  },
  actions: {
    async getCategories({ commit }) {
      let response = await axios.get(
        "external/company/catalog/gift-categories"
      );
      commit("setCategories", response.data.data);
    },
    async getSystemNotifications({ commit }) {
      let response = await axios.get("common/system-notifications");
      commit("setSystemNotifications", response.data.data);
    },
    async getUserGifts({ commit, getters }, page) {
      let response = await axios.get("external/me/gifts?page=" + page);
      if (response.data.status === "success" && response.data.data) {
        commit("setUserGifts", response.data.data);
      }
    },
    async getLoyaltyCards({ commit, getters }, page) {
      let response = await axios.get("external/me/loyalty-cards?page=" + page);
      if (
        response.data.status === "success" &&
        response.data.data.loyalty_cards
      ) {
        commit("setLoyaltyCards", response.data.data);
      }
    },

    async insertLoyaltyCard({ commit, getters }, card) {
      let loyCards = getters.loyaltyCards;
      loyCards.loyalty_cards = [...loyCards.loyalty_cards, card];
    },
    async getTransactions({ commit, getters }, page) {
      let response = await axios.get("external/me/transactions?page=" + page);
      console.log(response);
      if (
        response.data.status === "success" &&
        response.data.data.transactions
      ) {
        commit("setTransactions", response.data.data);
      }
    },
    async getHistory({ commit }) {
      let response = await axios.get("external/me/notifications");
      if (
        response.data.status === "success" &&
        response.data.data.user_notifications
      ) {
        commit("setHistory", response.data.data.user_notifications);
      }
    },
    async getToken({ commit }, token) {
      console.log(token);
      commit("setToken", token);
    },
    async getUser({ commit }) {
      try {
        let response = await axios.get("external/me", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.data.status === "success" && response.data.data) {
          commit("setUser", response.data.data);
        }
        return response.data.data;
      } catch (ex) {
        // if(ex.response.data.status === "error"){
        commit("setUser", null);
        // }
      }
    },
    async getGifts({ commit, getters }, params) {
      let response = await axios.get(
        "external/company/catalog/gifts?gift_category_id=" +
          params[0] +
          "&page=" +
          params[1]
      );
      // response.data.data.gifts.forEach(e => e.gift_category = id);
      // commit("setGifts", mergeDuplicatesByIds([...response.data.data.gifts, ...getters.gifts], 'gift_category'));
      commit("setGifts", response.data.data);
    },
    async getCompanyStores({ commit }) {
      let response = await axios.get("external/company/stores");
      // if(response.data.status === "success" && response.data.data.stores){
      commit("setCompanyStores", response.data.data.stores);
      // }
    },
  },
});
