import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: 'Aegean',
    meta: {
        title: 'Aegean'
    },
    component: () => import('@/views/all-home-version/MainDemo.vue')
  },
  {
    path: '/history',
    name: 'History',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/History.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/Terms.vue')
  },
  {
    path: '/home',
    name: 'Aegean',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/all-home-version/MainDemo.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Aegean'
    },  
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: {
      title: 'Aegean'
    },  
    component: () => import('@/views/ChangePassword.vue')
  },
  {
    path: '/login',
    name: 'Login',  
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/button',
    name: 'Button',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/Button.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/News.vue')
  },
  {
    path: '/categories/:id',
    name: 'Categories',
    meta: {
      title: 'Aegean'
    },
    component: () =>  import(
      "@/views/Categories.vue"
    ),
    props: route => ({ idString: `id ${route.params.id}` })
  },
  {
      path: '/my-gifts',
      name: 'Gifts',
      meta: {
        title: 'Aegean'
    },
      component: () => import('@/views/GiftsRedeemed.vue')
    },
    {
      path: '/my-cards',
      name: 'LoyaltyCards',
      meta: {
        title: 'Aegean'
      },
      component: () => import('@/views/LoyaltyCards.vue')
      },
    {
      path: '/our-news',
      name: 'OurNews',
      meta: {
        title: 'Aegean'
      },
    component: () => import('@/views/OurNews.vue')
    },
    {
      path: '/my-account',
      name: 'MyAccount',
      meta: {
        title: 'Aegean'
      },
    component: () => import('@/views/MyAccount.vue')
    },
    {
      path: '/promo-codes',
      name: 'PromoCodes',
      meta: {
        title: 'Aegean'
      },
      component: () => import('@/views/PromoCodes.vue')
  },
  
  {
    path: '/contact-form',
    name: 'ContactForm',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/ContactForm.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Aegean'
    },
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '*',
    component: Missing,
    meta: {
      title: 'Aegean'
    },
}
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
 })


export default router
