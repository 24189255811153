<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="450px"
      @click:outside="dialog = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn style="background: white" v-if="fromHeader" :ripple="false" v-bind="attrs" v-on="on" text >Σύνδεση</v-btn>
        <v-btn v-else :ripple="false" v-bind="attrs" v-on="on" text >Σύνδεση</v-btn>

      </template>
      <v-card>
      <v-form ref="form"
        v-model="valid"
        lazy-validation>
        <h3 style="text-align: center; padding: 5%">Σύνδεση</h3>
        <v-card-text>
            <v-row>
              <v-col cols="12" align="center" justify="center" >
                <v-img width="80" src="@/assets/images/logo/user.png" alt="Creative Agency" />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Κινητό*"
                  v-model="mobile" 
                  :rules="mobileRules"
                  type="text"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Κωδικός*"
                  type="password"
                  :rules="passwordRules"
                  v-model="password"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <!-- <small class="mt--20">*Υποχρεωτικά πεδία</small> -->
              </v-col>
              <v-col cols="12">
                <p class="error" v-if="errors">{{ errors }}</p>
              </v-col>
            </v-row>
          
        </v-card-text>
        <div class="mb-2 col-md-8 mt--20">
       
        <a
            color="white darken-1"
            text
            @click="dialog = false"
          >
             <ForgotPassword title="Ξέχασα τον κωδικό μου;" icon="mdi-plus" />
          </a>

        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="white darken-1"
            text
            @click="dialog = false"
          >
            Κλείσιμο
          </v-btn> -->
          <v-btn
            color="white darken-1"
            text
            block
            @click="login"
          >
            Σύνδεση
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import axios from 'axios'
    import { asyncLoading } from 'vuejs-loading-plugin'
    import ForgotPassword from "@/views/ForgotPassword";
    import { mapMutations, mapActions, mapGetters } from "vuex";

    export default {
       name: 'Login',
       components: {
        ForgotPassword,
      },
       props: {
          fromHeader: String
        },
       data() {
           return  {
             errors: '',
               valid: true,
               dialog: false,
               mobile: '',
               password: '',
               mobileRules: [
                v => !!v || 'Το κινητό τηλέφωνο είναι υποχρεωτικό',
                v => /^\d+$/.test(v) || 'Το κινητό τηλέφωνο πρέπει να περιέχει μόνο αριθμούς',
                v => (v && (""+v).length == 10) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς',
              ],
              passwordRules: [
                v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
              ],
           }
       },
       methods: {
           async login(e){
             try{
                if(this.$refs.form.validate()){
                      this.$loading(true)
                      e.preventDefault();
                      this.errors = '';
                        const response = await axios.post('external/users/login', 
                        {
                            mobile: this.mobile,
                            password: this.password,
                        }).then(response => {
                            
                            localStorage.setItem('token', response.data.access_token); 
                            this.$refs.form.reset();
                            this.$loading(false)
                            var paths = ["/", "/home"];
                            paths.includes(this.$router.currentRoute.path) ? this.$router.go(this.$router.currentRoute) : this.$router.push('/home') ;
                        }).catch(error => {
                          this.$loading(false)
                          console.log(error.response.data.message)
                          if(/[Uu]ser.*[Nn]ot\s([fF]ound|[eE]xist)/g.test(error.response.data.message)){
                            this.errors="Δεν βρέθηκε ο χρήστης. Παρακαλώ εγγραφείτε.";
                          } else if (error.response.data.status == 401) {
                            this.errors="Παρακαλώ ελέγξτε τα στοιχεία σας.";
                          } else {
                            this.errors="Κάτι πήγε στραβά, παρακαλώ ανανεώστε την σελίδα και προσπαθήστε ξανά.";
                          }
                        });
                  
                }else{
                  return false;
                }            
              }catch(e){
                  this.error = e.message;
                  return e;
              }
           }
       },
    };
</script>
