<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up"
      style="position: fixed"
      v-model="drawer"
      absolute
      temporary
      width="60vw"
    >
      <v-list-item class="pa-2">
        <v-spacer></v-spacer>
        <span large icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)"></span>
      </v-list-item>
      <v-list class="mb--0 pb--0">
       
         <v-list style="padding: 0;">
          <v-list-item v-if="user" @click="menuList = !menuList" link :ripple="false" text>
              <v-list-item-title> Μενού<v-icon right > mdi-chevron-down </v-icon> </v-list-item-title>
          </v-list-item>
          <v-list-item style="margin-left: 30px;" v-if="user && menuList" link :ripple="false" text to="/my-gifts">
            <v-list-item-title>Εξαργυρώσεις</v-list-item-title>
          </v-list-item>
          <v-list-item style="margin-left: 30px;" v-if="user && menuList" link :ripple="false" text to="/my-cards">
            <v-list-item-title>Οι κάρτες μου</v-list-item-title>
          </v-list-item>
          <v-list-item style="margin-left: 30px;" v-if="user && menuList" link :ripple="false" text to="/history">
            <v-list-item-title>Το ιστορικό μου</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list style="padding: 0;">
          <v-list-item @click="categoryList = !categoryList" link :ripple="false" text>
              <v-list-item-title> Κατάλογος δώρων<v-icon right > mdi-chevron-down </v-icon> </v-list-item-title>
          </v-list-item>
          <div v-if="categoryList" >
            <v-list style="margin-left: 30px; padding-bottom: 0px;" v-for="(category, i) in categories"
                                  :key="i">
              <v-list-item :ripple="false" text :to="'/categories/' + category.gift_category_id">
                <v-list-item-title>{{category.name.el}}</v-list-item-title>
              </v-list-item>
              
            </v-list>
          </div>
        </v-list>
        <v-list-item
          :ripple="false"
          to="/contact"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Δίκτυο πρατηρίων</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user"
          :ripple="false"
          to="/profile"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Ο λογαριασμός μου</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user"
          :ripple="false"
          @click="logout" href="javascript:void(0)"
          link
        >
         <v-list-item-content>
            <v-list-item-title>Αποσύνδεση</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <Login fromHeader=true v-if="!user" title="Σύνδεση" />
        <Register style="padding: 15px" fromHeader=true v-if="!user" title="Εγγραφή" />
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->
  <scroll-fixed-header :fixed.sync="fixed" :threshold="56">

    <v-app-bar
      dark
      elevation="0"
      color="transparent"
      absolute
      class="default-header"
    >

    <v-row style="height: 100% !important">     
      <v-col xl="6" lg="12"
        md="12"
        sm="12"
        cols="12"
        :class="{'changeMargin1' : user}"
        style="display: flex; justify-content: center; align-items: center;">
         <router-link to="/" class="logo">
        <slot name="logo"></slot>
        </router-link>
        <!-- End brand logo -->
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="ma-0 pa-0 hidden-md-and-up"
          @click="drawer = !drawer"
          v-html="iconSvg(icon)"
        >
        </v-btn>
      </v-col>

      <v-col xl="6" lg="12"
        md="12"
        sm="12"
        cols="12" :class="{'changeMargin' : user}">
        <!-- End mobile menu icon -->
        <v-toolbar-items style="height:100%" class="hidden-xs-only hidden-sm-only">
          <v-menu
              v-if="user"
              open-on-hover
              bottom
              min-width="240"
              offset-y
              transition="scroll-y-reverse-transition"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn :ripple="false" text v-bind="attrs" v-on="on">
              Μενού
              <v-icon dark right > mdi-chevron-down </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link :ripple="false" text to="/my-gifts">
                <v-list-item-title>Εξαργυρώσεις</v-list-item-title>
              </v-list-item>
              <v-list-item link :ripple="false" class="mt--20" text to="/my-cards">
                <v-list-item-title>Κάρτες</v-list-item-title>
              </v-list-item>
              <v-list-item link :ripple="false" class="mt--20" text to="/history">
                <v-list-item-title>Ιστορικό</v-list-item-title>
              </v-list-item>
              
            </v-list>
          </v-menu>
          <v-menu
              open-on-hover
              bottom
              min-width="240"
              offset-y
              transition="scroll-y-reverse-transition"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn :ripple="false" text v-bind="attrs" v-on="on">
              Κατάλογος δώρων<v-icon dark right > mdi-chevron-down </v-icon>
              </v-btn>
            </template>
          
            <v-list v-for="(category, i) in categories"
                                :key="i">
              <v-list-item link :ripple="false" text :to="'/categories/' + category.gift_category_id" >
                <v-list-item-title>{{category.name.el}}</v-list-item-title>
              </v-list-item>
              
            </v-list>
          </v-menu>
            <v-btn :ripple="false" text to="/contact">Δίκτυο πρατηρίων</v-btn>
            <v-btn v-if="user" :ripple="false" text to="/profile">Ο λογαριασμός μου</v-btn>
            <v-btn v-if="user" :ripple="false" text  @click="logout" href="javascript:void(0)">Αποσύνδεση</v-btn>
            <Login v-if="!user" title="Σύνδεση" icon="mdi-plus" />
            <Register v-if="!user" title="Εγγραφή" icon="mdi-plus" />
            

        </v-toolbar-items>
      </v-col>
    </v-row>
      <!-- End header menu item -->
    </v-app-bar>
      </scroll-fixed-header>

    <!-- End top header navbar -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import Login from "@/views/Login";
  import Register from "@/views/Register";

  export default {
    name: 'user',
    components: {
      Login,
      Register,
    },
    computed: {
      ...mapGetters (["user"]),
      ...mapGetters (["categories"]),
      ...mapGetters (["companyStores"]),
      ...mapGetters (["token"]),
    },
    async  created() {
        this.getCompanyStores();
        this.getCategories();
        if(!this.user && this.token){
          this.$loading(true);
          await this.getUser();
          this.$loading(false);
        }else{
          await this.getUser();
        }
    },
     methods: {
      ...mapActions(["getUser"]),
      ...mapActions(["getCategories"]),
      ...mapActions(["getCompanyStores"]),
      ...mapActions(["getToken"]),
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
      logout(){
        localStorage.removeItem('token');
        this.$loading(true);
        var paths = ["/", "/home"];
        paths.includes(this.$router.currentRoute.path) ? this.$router.go(this.$router.currentRoute) : this.$router.push('/home') ;
        this.$loading(false);
      }
    },
    data: () => ({
      fixed: false,
      drawer: false,
      menuList: false,
      categoryList: false,
      items: [
        { title: "About", to: "/about" },
        { title: "Contact", to: "/contact" },
      ],
      icon: "menu",
      closeIcon: "x",
    }),

   
  };
</script>

<style lang="scss">
  .feather-menu {
    color: #134075;
  }
  .headerPoints {
    // border-bottom: 1px solid lightgrey;
    text-align:center; 
  }
  .scroll-fixed-header.is-fixed > header {
    background-color: white !important;
    max-height: 120px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1450px) {
    .changeMargin {
      margin-left: -200px !important;
    }
    .changeMargin1 {
      margin-left: -100px !important;
    }
  }
  @media only screen and (min-width: 1450px) and (max-width: 1650px) {
    .changeMargin {
      margin-left: -100px !important;
    }
  }
</style>
