import axios from 'axios'

let baseUrl = 'https://api.roadcube.io/v1/';
let idToken = '4d7e5544-ca12-49d9-8f77-24b5514a5eaf';

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Web-App-Token'] = idToken;
axios.defaults.headers.common['X-Locale'] = 'el';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

