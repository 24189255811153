<template>
  <!-- <v-row justify="center"> -->
  <v-row>
    <v-dialog 
      v-model="dialog"
      persistent
      max-width="800px"
      @click:outside="dialog = false"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn class="newStyle" v-if="fromHome === true" :ripple="false" v-bind="attrs" v-on="on" text >Ξεκινήστε τώρα</v-btn>
        <v-btn style="background: white;" v-else-if="fromHeader === true" :ripple="false" v-bind="attrs" v-on="on" text >Εγγραφή</v-btn>
        <v-btn v-else :ripple="false" v-bind="attrs" v-on="on" text >Εγγραφή</v-btn>
      </template>
      <v-card>
      <v-form ref="form"
        v-model="valid"
        lazy-validation>
        <v-card-title class="pb-0">
        <div class="d-block caption text-center">
                      <v-subheader> <h3>Εγγραφή</h3></v-subheader>
                        </div>
                  </v-card-title>
          <v-card-title class="pb-0">
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-card-text class="">
                      <v-subheader> <h5></h5></v-subheader>
                  </v-card-text>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                >
                   <vue-country-code
                    v-model="country_id"
                    @onSelect="onSelect"
                    :preferredCountries="['gr', 'it', 'us']">
                </vue-country-code>
                 
                </v-col>
                <v-col
                  cols="10"
                  sm="10"
                  md="10"
                >
                 <v-text-field
                    label="Κινητό τηλέφωνο*"
                    hint="Κινητό τηλέφωνο"
                    v-model="mobile"
                    :rules="[rules.required,rules.integerOnly, rules.phoneRules]"
                    persistent-hint
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                  :disabled="userExists"
                    label="Όνομα"
                    hint="Όνομα"
                    v-model="first_name"
                    :rules="[rules.required]"
                    outlined
                  shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                  :disabled="userExists"
                    label="Επώνυμο*"
                    hint="Επώνυμο"
                    v-model="last_name"
                    persistent-hint
                    :rules="[rules.required]"
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <v-text-field
                  :disabled="userExists"
                    label="Ημερομηνία Γέννησης"
                    hint="Ημερομηνία Γέννησης"
                    v-model="birth_date"
                    :rules="[rules.required, rules.validDate]"
                    format="D/MMM/YYYY"
                    type="date"
                    outlined
                  shaped
                  ></v-text-field>
                 <!-- <v-menu
                 :disabled="userExists"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birth_date"
                    label="Ημερομηνία Γέννησης"
                    :rules="[rules.required]"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="birth_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>            -->
                </v-col>
                <v-col cols="12">   
                  <v-radio-group
                  :disabled="userExists"
                    v-model="gender"
                    row
                  >
                    <v-radio
                      label="Άντρας"
                      value="male"
                    ></v-radio>
                    <v-radio
                      label="Γυναίκα"
                      value="female"
                    ></v-radio>
                    <v-radio
                      label="Άλλο"
                      value="other"
                    ></v-radio>
                  </v-radio-group>
              </v-col>
                <v-col
                :disabled="userExists"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    label="Περιοχή / Πόλη*"
                    hint="Περιοχή / Πόλη"
                    v-model="city"
                    :rules="[rules.required]"
                    persistent-hint
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                 <v-text-field
                 :disabled="userExists"
                    label="Ταχυδρομικός κώδικας*"
                    hint="Ταχυδρομικός κώδικας"
                    :rules="[rules.required, rules.postalCodeRules]"
                    v-model="postal_code"
                    outlined
                    shaped
                  ></v-text-field>
                 
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                  :disabled="userExists"
                    label="Διεύθυνση*"
                    hint="Διεύθυνση"
                    v-model="address"
                    :rules="[rules.required]"
                    persistent-hint
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                  :disabled="userExists"
                    label="Email*"
                    v-model="email"
                    :rules="[rules.required, rules.emailRules]"
                    outlined
                  shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                  :disabled="userExists"
                    label="Κωδικός*"
                    type="password"
                    v-model="password"
                    :rules="[rules.required, rules.length]"
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                  :disabled="userExists"
                    label="Επιβεβαίωση Κωδικού*"
                    type="password"
                    v-model="password_confirmation"
                    :rules="[rules.required, rules.length, passwordConfirmationRule]"
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                  :disabled="userExists"
                    v-model="acceptTerms"
                    :rules="[rules.required]"
                    :label="`Αποδοχή όρων χρήσης`"
                    true-value=true
                    false-value=false
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                  :disabled="userExists"
                    v-model="acceptMarketing"
                    :label="`Επιθυμώ να λαμβάνω νέα & προσφορές από την Aegean`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    :disabled="acceptMarketing == false"
                    v-model="acceptSms"
                    :value="acceptMarketing == false ? false : this.acceptSms"
                    :label="`ΚΙΝΗΤΟ`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    :disabled="acceptMarketing == false"
                    v-model="acceptMail"
                    :value="acceptMarketing == false ? false : this.acceptMail"
                    :label="`EMAIL`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    :disabled="acceptMarketing == false"
                    v-model="acceptPush"
                    :value="acceptMarketing == false ? false : this.acceptPush"
                    :label="`PUSH`"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
            <small>*Υποχρεωτικά πεδία</small>
          </v-card-text>
          <v-card-actions v-if="!userExists">
            <v-spacer></v-spacer>
            <v-btn
              color="white darken-1"
              text
              @click="dialog = false"
            >
              Κλείσιμο
            </v-btn>
            <v-btn
              color="white darken-1"
              text
              @click="register"
            >
              Ολοκλήρωση
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
    import axios from 'axios'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import { mapMutations, mapActions, mapGetters } from "vuex";
    import moment from 'moment';

    export default {
        components: { moment },
       name: 'Register',
       components: {
          DatePicker
        },
        props: {
          fromHome: Boolean,
          fromHeader: String
        },
       data() {
           return  {
             birth_date: new Date().toISOString().substr(0, 10),
             country_id: '',
             userExists: false,
            "first_name": "",
            "last_name": "",
            "birth_date": "",
            "gender": "male",
            "address": "",
            "city": "",
            "password1": "",
            "postal_code": "",
            "mobile": "",
            "password": "",
            "password_confirmation": "",
            "user_notifications": [
              {
              "system_notification_id": 1,
              "value": false
              },
              {
              "system_notification_id": 2,
              "value": false
              },
              {
              "system_notification_id": 3,
              "value": false
              }
            ],
            modal: false,
            menu2: false,
            email: "",
            tos: false,
            marketing: false,
            acceptMarketing: false,
            acceptMail: false,
            acceptSms: false,
            acceptPush: false,
            acceptTerms: false,
            time1: null,
            value: null,
            gender: 'male',
            errors: [],
            valid: true,
            dialog: false,
            mobile: '',
            password: '',
            rules: {
              required: value => !!value || 'Το πεδίο είναι υποχρεωτικό.',
              length: v => (!v || v.length >= 6) || 'Ο κωδικός πρέπει να περιέχει πάνω απο 6 χαρακτήρες.',
              integerOnly: v => /^\d+$/.test(v) || 'Το πεδίο πρέπει να περιέχει μόνο αριθμούς',
              requiredCard: value => (!!value || this.addCard == 'assign_card') || 'Το πεδίο είναι υποχρεωτικό.',
              emailRules: v => (!v || /.+@.+\..+/.test(v)) || 'Το email δεν είναι έγγυρο.',
              postalCodeRules: v => (!v || /^\d{3}\s{0,1}\d{2}$/.test(v)) || 'Ο ταχυδρομικός κώδικας δεν είναι έγγυρος',
              phoneRules: v => (v && (""+v).length == 10) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς',
              validDate: v => (!v || (moment(new Date(v)) < moment(new Date()).subtract(14, "years") && moment(new Date(v)) > moment(new Date()).subtract(90, "years"))) ||
               'Επιλέξτε μία έγγυρη ημερομηνία γέννησης '
            },
           }
       },
       watch: {
          acceptMail: function (newQuestion, oldQuestion) {
            !newQuestion && !this.acceptSms && !this.acceptPush ?
             this.acceptMarketing = false : this.acceptMarketing = true
          },
          acceptSms: function (newQuestion, oldQuestion) {
            !newQuestion && !this.acceptPush && !this.acceptMail ?
             this.acceptMarketing = false : this.acceptMarketing = true
          },
          acceptPush: function (newQuestion, oldQuestion) {
            !newQuestion && !this.acceptSms && !this.acceptMail ?
             this.acceptMarketing = false : this.acceptMarketing = true
          },
          acceptMarketing: function (newQuestion, oldQuestion) {
            if(newQuestion) {
              this.acceptSms = true;
              this.acceptMail = true;
              this.acceptPush = true;
            }else{
              this.acceptSms = false;
              this.acceptMail = false;
              this.acceptPush = false;
            }
          },
          mobile: async function (newQuestion, oldQuestion) {
            if(newQuestion.length === 10){
              const response = await axios.post('external/users/mobile-checker', 
                {
                    mobile: newQuestion,
                }).then(response => {
                  if(response.data.data.exists){
                    this.errorTitle = "Αποτυχία"
                    this.errorText = "Ο χρήστης είναι ήδη εγγεγραμμένος. Παρακαλώ πατήστε σύνδεση και ξέχασα τον κωδικό μου εάν δεν θυμάστε τον κωδικό σας."
                    this.userExists = true;
                    this.$swal({
                      title: this.errorTitle,
                      text: this.errorText,
                      icon: "warning", //built in icons: success, warning, error, info
                      timer: 6000,
                        buttons: {
                          confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                          },
                          cancel: {
                            text: "Cancel",
                            value: false,
                            visible: true,
                            className: "",
                            closeModal: true,
                          }
                        }
                    });
                  }else{
                    this.userExists = false;
                  }

                }).catch(error => {
                    console.log(error)
                });
            }
              
          }
        },
        computed: {
              passwordConfirmationRule() {
                return () => (this.password === this.password_confirmation) || 'Οι κωδικοί δεν ταιριάζουν'
              },
              ...mapGetters (["systemNotifications"]),
            
        },
        async  created () {
            await this.getSystemNotifications();
        },
        methods: {
          moment: function (created_at) {
            return moment(String(created_at));
          },
          onSelect({name, iso2, dialCode}) {
              this.country_id = dialCode;
            },
           ...mapActions(["getSystemNotifications"]),
           async register(){

             // todo  "generate_card": false, or not?
             try{
                if(this.$refs.form.validate()){
                      this.errors = [];
                      this.$loading(true)
                        const response = await axios.post('external/users/register', 
                        {
                              "generate_card": true,
                              "assign_card": false,
                              "first_name": this.first_name,
                              "last_name": this.last_name,
                              "birth_date": this.birth_date,
                              "gender": this.gender,
                              "address": this.address,
                              "city": this.city,
                              "postal_code": this.postal_code,
                              "mobile": this.mobile,
                              "password": this.password,
                              "password_confirmation": this.password_confirmation,
                              "county_id": this.country_id,
                              "email": this.email,
                              "tos": true,
                              "marketing": Boolean(this.acceptMarketing),
                              "user_notifications": [
                                {
                                  "system_notification_id": parseInt(this.systemNotifications.find( item => item.name == "mail").system_notification_id),
                                  "value": Boolean(this.acceptMail)
                                },
                                {
                                  "system_notification_id": parseInt(this.systemNotifications.find( item => item.name.includes("sms")).system_notification_id),
                                  "value": Boolean(this.acceptSms)
                                },
                                {
                                  "system_notification_id": parseInt(this.systemNotifications.find( item => item.name == "push").system_notification_id),
                                  "value": Boolean(this.acceptPush)
                                }
                              ]
                        }).then(async response => {
                            await axios.post('external/users/login', 
                            {
                                mobile: this.mobile,
                                password: this.password,
                            }).then(response => {
                                this.$loading(false)
                                localStorage.setItem('token', response.data.access_token); 
                                var paths = ["/", "/home"];
                                paths.includes(this.$router.currentRoute.path) ? this.$router.go(this.$router.currentRoute) : this.$router.push('/home') ;
                            }).catch(error => {
                                this.$loading(false)
                                if (error.response.data.code == 401) {
                                    this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                                } 
                            });
                            this.$refs.form.reset();
                        }).catch(error => {
                            if (error.response.data.code == 400) {
                              this.$loading(false)
                              this.$swal({
                                title: "Ο χρήστης υπάρχει",
                                text: "Ο χρήστης είναι ήδη εγγεγραμμένος. Παρακαλώ πατήστε σύνδεση και ξέχασα τον κωδικό μου εάν δεν θυμάστε τον κωδικό σας.",
                                icon: "warning", //built in icons: success, warning, error, info
                                timer: 6000,
                                  buttons: {
                                    confirm: {
                                      text: "OK",
                                      value: true,
                                      visible: true,
                                      className: "",
                                      closeModal: true
                                    },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      className: "",
                                      closeModal: true,
                                    }
                                  }
                              });
                            }else{
                              this.$loading(false)
                              this.$swal({
                                title: "Αποτυχία",
                                text: "Κάτι πήγε στραβά. Παρακαλώ ξαναπροσπαθήστε.",
                                icon: "error", //built in icons: success, warning, error, info
                                timer: 3000,
                                  buttons: {
                                    confirm: {
                                      text: "OK",
                                      value: true,
                                      visible: true,
                                      className: "",
                                      closeModal: true
                                    },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      className: "",
                                      closeModal: true,
                                    }
                                  }
                              });
                            }
                        });
                  
                }else{
                  return false;
                }            
              }catch(e){
                  this.error = e.message;
                  return e;
              }}
       },
    };
</script>

<style lang="scss" scoped>
.v-btn.newStyle {
    background-color: #004a91;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.8rem 2rem 2rem 2rem;
    border: 2px solid #004a91;
    position: relative;
    margin-left: 15px;
    margin-top: 15px;
}
</style>