<template>
  <!-- Start Footer Style Two  -->
  <div
    class="footer-style-3 ptb--100"
  >
      <div class="wrapper">
          <v-row  align="center" justify="center">
              <v-col  align="center" justify="center" lg="2" md="2" sm="5" cols="5" class="mt--15" >
                  <div class="inner text-center">
                        <img :src="logo" alt="Logo images" />
                  </div>
              </v-col>
              <!-- End .col -->
              <v-col  lg="3" md="3" sm="12" cols="12"  class=" mb--30 ml_sm--30 mt_sm--30">

                  <div class="inner text-center">
                    <p class="footerStyle pb--10"><span class="pl--5"> Ακτή Κονδύλη 10, Πειραιάς, Ελλάδα</span></p>
                    <p class="footerStyle pb--10"><span class="pl--5">2103006633</span></p>
                    <p class="footerStyle pb--10"><span class="pl--5">info@aegeanprivilegecard.gr </span></p>
                  </div>
              </v-col>
              <v-col  align="center" justify="center" lg="2" md="2" sm="5" cols="5" class="mt_sm--30" >
                  <div class="inner text-center">
                        <v-row align="end" >
                            <v-col lg="6" md="12" sm="12" cols="12">
                                <a href="https://apps.apple.com/us/app/id1489983713" target="blank" class="app-btn1"><img src="@/assets/images/blog/appstore.png" alt=""></a>
                            </v-col>
                            
                            
                        </v-row>
                        <v-row align="end" class="mt--20">
                            <v-col lg="6" md="12" sm="12" cols="12">
                                <a href="https://play.google.com/store/apps/details?id=com.rcflavorproba" target="blank" class="app-btn2"><img src="@/assets/images/blog/playstore.png" alt=""></a>

                            </v-col>
                        </v-row>
                  </div>
              </v-col>
              <!-- End .col -->
          </v-row>
          <hr class="mt_sm--80" style="margin-left: 20%; margin-right: 20%;">
          <v-row align="center" class="mt--100">
              <!-- End .col -->
              <v-col lg="4" md="4" sm="12" cols="12">
                  <div class="inner text-md-right text-center mt_md--20 mt_sm--20">
                      <div class="text">
                          <p>
                              Copyright © {{ new Date().getFullYear() }} RoadCube. All Rights Reserved.
                          </p>
                      </div>
                  </div>
              </v-col>
              <v-col lg="2" md="2" sm="2" cols="12">
              </v-col>
               <v-col lg="4" md="4" sm="12" cols="12">
                  <div class="inner text-md-right text-center mt_md--20 mt_sm--20">
                      <div class="text">
                          <div class="">
                           <ul class="rn-lg-size justify-center liststyle">
                              <li>
                                <a href="/terms" target="_blank"> Όροι &amp; Προϋποθέσεις</a>
                              </li></ul>
                            </div>
                      </div>
                  </div>
              </v-col>
              
              
              <!-- End .col -->
          </v-row>
      </div>
  </div>
  <!-- End Footer Style Two  -->
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/",
          },
          {
            icon: "fa-instagram-square",
            url: "https://www.instagram.com/",
          },
          ],
          socialListText: [
              {
                  text: "Αρχική",
                  url: "/home",
              },
              {
                  text: "Κατάλογος δώρων",
                  url: "/categories",
              },
              {
                  text: "Επικοινωνία",
                  url: "/contact",
              },
          ],
      };
    },
  };
</script>

<style scoped>
   .footerStyle {
      font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #333;
      font-weight: 300;
   }
   .fa, .fas, .fa-linkedin, .fa-facebook-f, .fa-instagram, .fa-youtube-square {
    color: #222;
   }
   h4 {
      color: #222;
      font-size: 18px;
      margin-bottom: 48px;
      font-weight: 700;
   }
   a:hover {
    text-decoration: none;
   }
   p:hover {
       color: #222;
   }
</style>